import services from "@/services/courses";
import flexService from "@/services/flex";
import API from "@/services/config";
import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "./loadingStates";
import { Courses } from "@inctec/elearning-ui-client";

export default {
  state: {
    list: [],
    loadingState: stateInitial,
    publications: [],
    publicationsLoadingState: stateInitial,
    courseContent: {},
    courseContentLoadingState: stateInitial,
    // TODO: check if this is used somewhere, replace and renove
    currentContentMetadata: null,
    assignmentRulesLoadingState: { ...stateInitial, result: [] },

    userStateCertificatesLoadingState: { ...stateInitial, result: [] },
    userStateCertificatesOptions: {},
    userStateCertificatesPage: null,
  },
  mutations: {
    loadingState(state) {
      state.loadingState = stateLoading;
    },
    loadedState(state) {
      state.loadingState = stateLoaded;
    },
    errorState(state) {
      state.loadingState = stateError;
    },

    publicationsLoadingState(state) {
      state.publicationsLoadingState = stateLoading;
    },
    publicationsLoadedState(state) {
      state.publicationsLoadingState = stateLoaded;
    },
    publicationsErrorState(state) {
      state.publicationsLoadingState = stateError;
    },

    courseContentLoadingState(state) {
      state.courseContentLoadingState = stateLoading;
    },
    courseContentLoadedState(state) {
      state.courseContentLoadingState = stateLoaded;
    },
    courseContentErrorState(state) {
      state.courseContentLoadingState = stateError;
    },

    assignmentRulesLoading(state) {
      state.assignmentRulesLoadingState = {
        ...stateLoading,
        result: state.assignmentRulesLoadingState.result,
      };
    },
    assignmentRulesLoaded(state, result) {
      state.assignmentRulesLoadingState = { ...stateLoaded, result };
    },
    assignmentRulesError(state) {
      state.assignmentRulesLoadingState = {
        ...stateError,
        result: state.assignmentRulesLoadingState.result,
      };
    },

    userStateCertificatesLoadingState(state, options) {
      state.userStateCertificatesLoadingState = {
        ...stateLoading,
        result: state.userStateCertificatesLoadingState.result,
      };
      state.userStateCertificatesOptions = options;
    },
    userStateCertificatesLoadedState(state, response) {
      state.userStateCertificatesLoadingState = {
        ...stateLoaded,
        result: response.result,
      };
      state.userStateCertificatesPage = {
        total: response.total,
        pagesTotal: response.pagesTotal,
      };
    },
    userStateCertificatesErrorState(state) {
      state.userStateCertificatesLoadingState = stateError;
    },

    addCourseToProgram(state, payload) {
      const list = state.list;
      const element = list.find((item) => item.id === payload);
      if (element) {
        element.programme = true;
      }
    },
    resetReserve(state) {
      state.currentContentMetadata = null;
    },
    initCourseById(state, payload) {
      // NOTE: This does two things: it adds hardcoded image URLs to the item,
      // which defies all logic but is used in at least 5 different places.
      // On the other hand, this searches in the list of all courses for a particular course,
      // and replaces it with more details, which can only be retrieved through the users/courses/{ id } endpoint. E.g. DOCUMENTS
      const { id } = payload;
      payload.image = `${API.coursesURL}${id}/pictures/image`;
      payload.playImage = `${API.coursesURL}${id}/pictures/playImage`;
      payload.cardImage = `${API.coursesURL}${id}/pictures/cardImage`;
      const list = state.list;
      const index = list.findIndex((item) => item.id === id);
      if (index > -1) {
        // Currently the getCourseById Endpoint is missing data, like managers, thus we have to merge
        const course = list[index];
        list.splice(index, 1, { ...course, ...payload });
      } else {
        state.list.push(payload);
      }
    },

    initPublishedCourses(state, payload) {
      state.publications = [];
      if (payload) {
        state.publications = payload;
      }
    },
    initCourses(state, payload) {
      state.list = [];
      if (!payload || payload.error) {
        return;
      }
      state.list = payload || [];
      state.list.forEach((item) => {
        const { id } = item;
        item.image = `${API.coursesURL}${id}/pictures/image`;
        item.playImage = `${API.coursesURL}${id}/pictures/playImage`;
        item.cardImage = `${API.coursesURL}${id}/pictures/cardImage`;
      });
    },

    initAllCourses(state, payload) {
      state.list = [];
      if (payload && payload.length) {
        state.list = payload || [];
        state.list.forEach((itm) => {
          const item = itm;
          const { id } = item;
          item.image = `${API.coursesURL}${id}/pictures/image`;
          item.playImage = `${API.coursesURL}${id}/pictures/playImage`;
          item.cardImage = `${API.coursesURL}${id}/pictures/cardImage`;
        });
      }
    },
    deleteCourseById(state, id) {
      const list = state.list;
      const index = list.findIndex((item) => item.id === id);
      if (index >= 0) {
        list.splice(index, 1);
      }
    },
    setCourseContent(state, payload) {
      // NOTE: the way this is being handled means that the content can be overriden at any time
      // It is also not safe to assume any type for the content
      // TODO: this is currently left in since it is hard to figure out where it is being used, but it should be removed and courseDetails used instead
      const { id, data } = payload;
      // the active courseContent is hold in the variable state.courseContent (link to content)
      // the course.content variable contains the contentId
      // const foundItem = state.list.find((item) => item.id === +id);
      // if (foundItem) {
      //   foundItem['content'] = data;
      // }
      // This is for setting course Details
      state.courseContentLoadedState = stateLoaded;
      state.courseContent = payload.data;
    },
    updateCourse(state, payload) {
      const { id, ...result } = payload;
      const foundItem = state.list.find((item) => item.id === +id);
      if (!foundItem) {
        return;
      }
      Object.keys(result).forEach((key) => {
        foundItem[key] = result[key];
      });
    },
    addCourseContent(state, payload) {
      const { id, ...result } = payload;
      const foundItem = state.list.find((item) => item.id === +id);
      if (!foundItem) {
        return;
      }
      Object.keys(result).forEach((key) => {
        foundItem[key] = result[key];
      });
    },
    updateCourseContent(state, payload) {
      if (!payload || !payload.id) {
        return;
      }
      const course = state.list.find((item) => item.id === payload.id);
      const courseIndex = state.list.findIndex(
        (item) => item.id === payload.id,
      );
      if (course) {
        Object.keys(payload).forEach((key) => {
          course[key] = payload[key];
        });

        // fix watch problem
        state.list = [
          ...state.list.slice(0, courseIndex),
          course,
          ...state.list.slice(courseIndex + 1),
        ];
      }
    },
    publishCourse(state, payload) {
      payload.forEach((publication) => state.publications.push(publication));
    },
    unpublishCourse(state, payload) {
      state.publications.splice(
        state.publications.findIndex(
          (pub) =>
            pub.accountId === payload.accountId &&
            pub.courseId === payload.courseId,
        ),
        1,
      );
    },
    addCourse(state, payload) {
      if (!payload || !payload.id) {
        return;
      }
      const { id } = payload;
      const result = payload;
      result.image = `${API.coursesURL}${id}/pictures/image`;
      result.playImage = `${API.coursesURL}${id}/pictures/playImage`;
      result.cardImage = `${API.coursesURL}${id}/pictures/cardImage`;
      state.list.push(payload);
    },
    removeCourseById(state, id) {
      state.list = state.list.filter((item) => item.id !== id);
    },
    flushCourses(state) {
      state.loadingState = stateInitial;
      state.list = [];
    },
  },
  actions: {
    async loadUserStateCertificates({ commit }, options) {
      commit("userStateCertificatesLoadingState");
      const response = await services.getUploadedCertificates(options).then(
        (response) => commit("userStateCertificatesLoadedState", response),
        (error) => commit("userStateCertificatesErrorState", error),
      );
      return response;
    },

    addCourseToProgram({ commit }, id) {
      commit("addCourseToProgram", id);
    },
    // NOTE: this does not - as the name suggests - load course data.
    // this merely adds data to existing courses.
    // NOTE: This can't be used as admin - the user has to have the role user in an account to use this endpoint!
    initCourseById({ commit }, id: number) {
      commit("loadingState");

      return Courses.getUserCourse({
        path: {
          courseId: `${id}`,
        },
      }).then(
        (response) => {
          if (response.data !== undefined) {
            commit("loadedState");
            commit("resetReserve");
            if (response.data) {
              commit("initCourseById", response.data);
              if (response.data.documents) {
                return response.data.documents;
              }
            }
            return false;
          } else {
            commit("errorState", response.error);
          }
        },
        (error) => {
          commit("errorState", error);
        },
      );
    },
    flushCourses({ commit }) {
      return new Promise((res) => {
        commit("flushCourses");
        return res(undefined);
      });
    },

    /* eslint-disable */
    downloadDocumentById({}, payload) {
      /* eslint-enable */
      const { id, courseId } = payload;
      window.open(`${API.documentsURL}${courseId}/documents/${id}`);
    },
    flushAllCourses({ commit }) {
      commit("flushCourses");
      commit("flushMyProgram");
      commit("flushCompletedList");
      commit("flushRoles");
    },

    initCourses({ commit }) {
      commit("loadingState");
      Courses.searchUserCourses({
        // not used at the moment
        body: { page: 1, pageSize: 1 },
      }).then(
        (response) => {
          if (response.data !== undefined) {
            commit("loadedState");
            commit("initCourses", response.data.result);
          } else {
            commit("errorState", response.error);
          }
        },
        (error) => {
          commit("errorState", error);
        },
      );
    },
    finishCourseById({ commit }, id) {
      commit("removeCourseById", id);
      commit("removeProgramCourseById", id);
    },
    getSCORM({ commit, getters }, id) {
      commit("courseContentLoadingState");
      const { startPath, contentId } = getters.getCourseStartPath(id);
      if (!startPath) {
        return false;
      }
      return services.GET_SCORM(contentId, startPath).then((data) => {
        if (typeof data === "string") {
          commit("setCourseContent", {
            data,
            id,
          });
        }
      });
    },
    getStaticCourse({ commit }, id) {
      commit("courseContentLoadingState");
      return services.GET_STATIC_COURSE(id).then(
        (data) => {
          commit("setCourseContent", {
            data,
            id,
          });
        },
        (error) => {
          commit("courseContentErrorState", error);
        },
      );
    },
    initAllCourses({ commit, state }, options) {
      if (
        options &&
        options.keepCache &&
        (state.loadingState.loading || state.loadingState.loaded)
      ) {
        return;
      }
      commit("loadingState");
      return Courses.searchCourses({}).then(
        (response) => {
          if (response.data !== undefined) {
            commit("loadedState");
            commit("initAllCourses", response.data.result);
          } else {
            commit("errorState", response.error);
          }
        },
        (error) => {
          commit("errorState", error);
        },
      );
    },
    addCourse({ commit }, payload) {
      commit("loadingState");
      return services.COURSE_CREATE(payload).then(
        (data) => {
          commit("addCourse", data);
          commit("loadedState");
          commit("addNewNotification", {
            title: "success",
            text: `courseCreated`,
            notificationType: "success",
          });
          return data;
        },
        (errorResponse) => {
          commit("errorState", errorResponse.error);
        },
      );
    },
    updateCourse({ commit }, payload) {
      commit("loadingState");
      return services.PATCH_COURSE(payload).then(
        (response) => {
          commit("updateCourse", response);
          commit("loadedState");
          commit("addNewNotification", {
            title: "success",
            text: "courseContentUpdated",
            notificationType: "success",
          });
        },
        (errorResponse) => {
          commit("errorState", errorResponse.error);
        },
      );
    },
    async copyCourse({ commit }, payload) {
      return await services.COPY_COURSE(payload).then((response) => {
        commit("addNewNotification", {
          title: "success",
          text: "courseCopySuccess",
          notificationType: "success",
        });
      });
    },
    async transferCourse({ commit }, payload) {
      return await services.TRANSFER_COURSE(payload).then((response) => {
        commit("addNewNotification", {
          title: "success",
          text: "courseTransferSuccess",
          notificationType: "success",
        });
      });
    },
    addCourseContent({ commit }, payload) {
      commit("loadingState");
      return services.POST_CONTENT(payload).then(
        (data) => {
          commit("addCourseContent", data);
          commit("loadedState");
          return true;
        },
        (errorResponse) => {
          commit("errorState", errorResponse.error);
        },
      );
    },
    uploadSCORM({ commit }, payload) {
      return services.UPLOAD_SCORM(payload).then(
        (data) => {
          return data;
        },
        (error) => {},
      );
    },
    uploadStaticCourseContent({ commit }, payload) {
      return services.UPLOAD_STATIC_COURSE(payload).then(
        (data) => {
          return data;
        },
        (error) => {},
      );
    },
    uploadQuiz({ commit }, payload) {
      return services.UPLOAD_QUIZ(payload).then(
        (data) => {
          return data;
        },
        (error) => {},
      );
    },
    uploadFlex({ commit }, payload) {
      return flexService.UPLOAD_FLEX(payload).then(
        (data) => {
          return data;
        },
        (error) => {},
      );
    },
    updateCourseContent({ commit }, payload) {
      return services.PATCH_SCORM(payload).then(
        (data) => {
          let isError = false;
          /* eslint-disable */
          if (data._status) {
            const { documents, images } = data._status;
            /* eslint-enable */
            if (documents) {
              if (
                documents.add &&
                documents.add.error &&
                documents.add.error.length
              ) {
                const errors = documents.add.error;
                errors.forEach((item) => {
                  const key = Object.keys(item)[0];
                  commit("addNewNotification", {
                    title: "error",
                    text: `${key} - ${item[key]}`,
                    notificationType: "error",
                  });
                });
                isError = true;
              }
              if (
                documents.remove &&
                documents.remove.error &&
                documents.remove.error.length
              ) {
                const errors = documents.remove.error;
                errors.forEach((item) => {
                  const key = Object.keys(item)[0];
                  commit("addNewNotification", {
                    title: "error",
                    text: `${key} - ${item[key]}`,
                    notificationType: "error",
                  });
                });
                isError = true;
              }
            }
            if (images.errors && images.errors.length) {
              const errors = images.errors;
              errors.forEach((item) => {
                const key = Object.keys(item)[0];
                commit("addNewNotification", {
                  title: "error",
                  text: `${key} - ${item[key]}`,
                  notificationType: "error",
                });
              });
              isError = true;
            }
          }
          if (!isError) {
            commit("addNewNotification", {
              title: "success",
              text: "courseContentUpdated",
              notificationType: "success",
            });
          }
          commit("updateCourseContent", data);
          return data;
        },
        (error) => {},
      );
    },
    uploadQuizAuthorizations({ commit }, payload) {
      return services.UPLOAD_QUIZ_AUTHORIZATIONS(payload).then(
        (data) => {
          commit("addNewNotification", {
            title: "success",
            text: "fileUploaded",
            notificationType: "success",
          });
          return data;
        },
        (errorResponse) => {},
      );
    },
    // NOTE: though named init published courses, this actually initializes publications
    // as payload, this can take {course: courseId, account: accountId} to filter publications by account and/or course
    initPublishedCourses({ commit }, payload) {
      commit("publicationsLoadingState");
      services.getPublishedCourses(payload).then(
        (data) => {
          commit("initPublishedCourses", data.result);
          commit("publicationsLoadedState");
        },
        (errorResponse) => {
          commit("publicationsErrorState", errorResponse.error);
        },
      );
    },
    patchPublishedCourse({ commit, dispatch }, payload) {
      commit("publicationsLoadingState");
      return services.patchPublication(payload.id, payload.patch).then(
        (response) => {
          commit("publicationsLoadedState");
        },
        (errorResponse) => {
          commit("publicationsErrorState", errorResponse.error);
        },
      );
    },

    publishCourse({ commit }, payload) {
      commit("publicationsLoadingState");
      return services.publishCourseBatch(payload).then(
        (response) => {
          commit("publishCourse", response.publications);
          commit("publicationsLoadedState");
          commit("addNewNotification", {
            title: "success",
            text: "coursePublished",
            notificationType: "success",
          });
        },
        (errorResponse) => {
          commit("publicationsErrorState", errorResponse.error);
        },
      );
    },
    unpublishCourse({ commit }, payload) {
      commit("publicationsLoadingState");
      return services.deletePublication(payload).then(
        (response) => {
          commit("unpublishCourse", payload);
          commit("addNewNotification", {
            title: "success",
            text: "courseUnPublished",
            notificationType: "success",
          });
          commit("publicationsLoadedState");
        },
        (errorResponse) => {
          commit("publicationsErrorState", errorResponse.error);
        },
      );
    },
    deleteCourseById({ commit }, id) {
      commit("loadingState");
      return services.DELETE_COURSE(id).then(
        (data) => {
          commit("deleteCourseById", id);
          commit("loadedState");
          return true;
        },
        (errorResponse) => {
          commit("errorState", errorResponse.error);
        },
      );
    },

    async loadAssignmentRules({ commit }, propertyId) {
      commit("assignmentRulesLoading");
      const response = await services
        .getAssignmentRules(propertyId)
        .catch((error) => {
          commit("assignmentRulesError");
          throw error;
        });
      commit("assignmentRulesLoaded", response.result);
    },

    async addAssignmentRules(
      { commit, dispatch },
      { propertyId, assignments },
    ) {
      commit("assignmentRulesLoading");
      const response = await services
        .putAssignmentRules(propertyId, { assignments })
        .catch((error) => {
          commit("assignmentRulesError");
          throw error;
        });
      dispatch("loadAssignmentRules", propertyId);
      return response;
    },
  },
  getters: {
    assignmentRulesLoadingState: (state) => state.assignmentRulesLoadingState,
    assignmentRules: (state) => state.assignmentRulesLoadingState.result,
    getCourseById: (state) => (id) => state.list.find((item) => item.id === id),

    getCourseMeta: (state) => state.meta,
    getPublicationsAcccounts: (state) => {
      const list = state.publications || [];
      return list.map((item) => item.accountId);
    },
    getPublications: (state) => state.publications,
    getPublicationLoadingState: (state) => state.publicationsLoadingState,
    // TODO: this is just returning the list of all courses, this doesn't seem to be unpublished courses
    getUnpublishedCourses: (state) => state.list,
    getCoursesList: (state) => state.list,
    getCoursesLoadingState: (state) => state.loadingState,
    getFormatedCoursesList: (state) =>
      state.list.map((item) => ({
        key: item.id,
        value: item.title,
      })),
    // TODO: courses don't seem to have the property isPublished, this won't work
    getPublishedCourses: (state) => state.list.filter((item) => item.published),
    getPublishedCoursesByAccount: (state) => (accountId) => {
      const publishedCourses = state.list.filter((item) => item.published);

      const result = [] as any[];
      publishedCourses.forEach((item) => {
        item.coursesPublished.map((acc) => {
          if (
            acc.account_id === accountId &&
            acc.visibility !== undefined &&
            !result.includes(item)
          ) {
            result.push(item);
          }
        });
      });

      return result;
    },
    getCourseStartPath: (state) => (id) => {
      const course = state.list.find((item) => item.id === id);
      if (course && course.metadata && course.metadata.start) {
        return { startPath: course.metadata.start, contentId: course.content };
      }
      return false;
    },
    getCourseContent: (state) => state.courseContent,
    getCourseContentLoadingState: (state) => state.courseContentLoadingState,

    getUserStateCertificatesLoadingState: (state) =>
      state.userStateCertificatesLoadingState,
    getUserStateCertificatesOptions: (state) =>
      state.userStateCertificatesOptions,
    getUserStateCertificatesPage: (state) => state.userStateCertificatesPage,

    getFormatedUnpublishedCourses: (state) =>
      state.list.map((item) => ({
        id: item.id,
        title: item.title,
      })),
    isCourseHasContent: (state) => (id) => {
      const list = state.list;
      const found = list.find((item) => item.id === id);
      if (found) {
        return !!found.content;
      }
      return false;
    },
    getReserveMetadata: (state) => state.currentContentMetadata,
    getCourseMetadata: (state) => (id) => {
      const foundItem = state.list.find((item) => item.id === id);
      if (foundItem && foundItem.metadata) {
        return foundItem.metadata;
      }
      return false;
    },
    getCourseNotesById: (state) => (id) => {
      const foundItem = state.list.find((item) => item.id === id);
      if (foundItem && foundItem.notes) {
        return foundItem.notes;
      }
      return false;
    },
    getPublicatedCourses: (state, getters) => {
      const publishedCourses = getters.getPublications || [];
      return state.list.filter((item) =>
        publishedCourses.find(
          (publication) => publication.courseId === item.id,
        ),
      );
    },
    getFormatedPublicatedCourses: (state, getters) => {
      const list = getters.getPublicatedCourses || [];
      return list.map((item) => ({
        key: item.id,
        value: item.title,
      }));
    },
  },
};
