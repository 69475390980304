<template lang="pug">
.flex(v-if="props.code !== undefined")
  .fi.mr-1(:class="`fi-${props.code.toLowerCase()}`")
  span {{ name }}
</template>

<script lang="ts" setup>
import { countryFromCode } from "@/utils/countries";
import { computed, onMounted } from "vue";

const props = defineProps<{
  code: string;
}>();

const name = computed(() => countryFromCode(props.code));
</script>
