import getToken from "@/utils/getToken";
import { mapResponse, printError } from "@/utils/mapResponse";
import axios from "axios";
import API from "./config";
import { requestSettings, deleteSettings, postSettings } from "./util";
/**
 *  @module Courses service
 */

export default {
  /**
   * @typedef {Object} Publication
   * @property {number} id - id
   * @property {number} courseId - course id
   * @property {(string|null)} expiration - expiration date
   * @property {number} licences - number of licences
   * @property {boolean} visibility - visibility flag
   */
  /**
   * @typedef {Object} Metadata
   * @property {string} duration - duration of the course (ISO)
   * @property {Array<object>} chapters - chapters array
   * @property {string} schema - course schema
   * @property {string} schema - entry path (URL)
   * @property {string} title - course title
   * @property {string} version - coourse version
   */

  /**
   * @typedef {Object} Assignment
   * @property {(string|null)} duedate - due datetime (ISO)
   * @property {number} id - id
   * @property {(string|null)}  structureItem- structure item
   * @property {string} username - username
   */

  /**
   * @typedef {Object} Assignments
   * @property {boolean} visibility - visibility flag
   * @property {Array<Assignment>} assignments - chapters array
  
   */

  /**
   * @typedef {Object} Course
   * @property {boolean} active - is active flag
   * @property {string} cardImage - card image (small version)
   * @property {boolean} content - true if content is uploaded
   * @property {string} created - creation datetime (ISO)
   * @property {boolean} deleted - true if the course is unactive
   * @property {string} description - description
   * @property {number} id - id
   * @property {string} image - image
   * @property {string} language - language code
   * @property {Array<number>} managers - array of manager's ids
   * @property {Metadata} metadata - metadata
   * @property {string} modified - updation datetime (ISO)
   * @property {string} notes - notes
   * @property {(string|null)} override_duration -
   * @property {string} playImage - preview image
   * @property {Array<string>} tags - tags array
   * @property {string} title - title
   */

  /**
   * API call to get all courses
   * @returns {Array<Course>} array of courses
   */
  GET_ALL_COURSES() {
    return axios.get(API.coursesURL).then(mapResponse).catch(printError);
  },

  /**
   * API call to get scorm file by id (contentId)
   * This call checks if the given path to a SCORM start file returns an HTML file
   * and if it does, it returns the URL to this file.
   * If the returned file is not an HTML file, it returns an object with an error property.
   * @param {number} id - course content id
   * @param {string} startPath - scorm path
   * @returns {String} html iframe body
   */
  GET_SCORM(id, startPath) {
    return axios
      .get(`${API.coursesURL}${id}/content/${startPath}`)
      .then((response) => response.data)
      .then((data) => {
        if (data.toLowerCase().indexOf("<html".toLowerCase()) !== -1) {
          return `${API.coursesURL}${id}/content/${startPath}`;
        }
        return {
          error: true,
        };
      });
  },

  GET_STATIC_COURSE(id) {
    return axios
      .get(`${API.baseUrl}staticCourses/${id}`)
      .then(mapResponse)
      .catch(printError);
  },

  searchUserAssignments(body) {
    return axios
      .post(`${API.baseUrlV2}assignments/search`, body)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to assign courses
   * @param {object} payload - payload object
   * @param {number} payload.courseId - course id
   * @param {number} payload.accountId - account id
   * @param {boolean} payload.mandatory - mandatory flag
   * @param {string} payload.duedate - due date string (ISO)
   * @param {string} payload.type - type (account or user)
   * @returns {Promise<Response>} Fetch response, status 204 if success
   */
  ASSIGN_COURSE_BATCH(body) {
    return axios
      .post(`${API.baseUrlV2}assignments/batch`, body)
      .then(mapResponse)
      .catch(printError);
  },

  // NOTE: body contains only course at the moment, and will assign that course to the current user
  ASSIGN_COURSE(body) {
    return axios
      .put(`${API.baseUrlV2}assignments`, body)
      .then(mapResponse)
      .catch(printError);
  },
  /**
   * API call to unassign courses
   * @param {object} payload - payload object
   * @param {number} payload.users - user id
   * @param {number} payload.course - course id
   * @returns {Promise<Response>} Fetch response, status 204 if success
   */
  UNASSIGN_COURSE(payload) {
    const params = new URLSearchParams({
      course: payload.course,
      users: JSON.stringify(payload.users),
    });
    return axios
      .delete(`${API.baseUrlV2}assignments/?${params.toString()}`)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * Rule based assignment API, for documentation see shogun
   */

  getAssignmentRules(umapId) {
    return axios
      .get(`${API.baseUrlV2}assignment-rules/${umapId}`)
      .then(mapResponse)
      .catch(printError);
  },

  putAssignmentRules(umapId, rules) {
    return axios
      .put(`${API.baseUrlV2}assignment-rules/${umapId}`, rules)
      .then(mapResponse)
      .catch(printError);
  },

  triggerAssignmentRules(rules) {
    return axios
      .post(`${API.baseUrlV2}assignment-rules/trigger`, rules)
      .then(mapResponse);
  },

  /**
   * API call to add new courses
   * @param {object} payload - payload object
   * @param {string} payload.title - course title
   * @param {string} payload.notes - course notes
   * @param {boolean} payload.active - is active flag
   * @param {string} payload.language - language code
   * @param {array} payload.managers - array of manager's ids
   * @returns {Course} added course object
   */

  COURSE_CREATE(payload) {
    return axios
      .post(API.coursesURL, payload)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to update courses information
   * @param {object} payload - payload object
   * @param {number} payload.id - course id
   * @param {string} payload.title - course title
   * @param {string} payload.notes - course notes
   * @param {boolean} payload.active - is active flag
   * @param {string} payload.language - language code
   * @param {array} payload.managers - array of manager's ids
   * @returns {Course} updated course object
   */

  PATCH_COURSE(payload) {
    return axios
      .patch(`${API.coursesURL}${payload.id}`, payload)
      .then(mapResponse);
  },

  /**
   * API call to copy a course
   * @param {object} payload - payload object
   * @param {string} payload.id - course id
   * @param {string} payload.name - new course name
   * @returns {Course} copied course object
   */
  COPY_COURSE(payload) {
    return axios
      .post(`${API.flexServices}copycourse/${payload.id}`, {
        name: payload.name,
      })
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to transfer a course
   * @param {object} payload - payload object
   * @param {string} payload.id - course id
   * @param {string} payload.systemUrl - url of target system
   * @param {string} payload.email - username for target system
   * @param {string} payload.password - password for target system
   * @returns {Course} transferred course object
   */
  TRANSFER_COURSE(payload) {
    return axios
      .post(`${API.flexServices}transfer/course/${payload.id}`, {
        systemUrl: payload.systemUrl,
        email: payload.email,
        password: payload.password,
      })
      .then(mapResponse)
      .catch(printError);
  },

  getPublishedCourses(request) {
    return axios
      .post(`${API.baseUrlV2}publications/search`, request)
      .then(mapResponse)
      .catch(printError);
  },

  publishCourse(request) {
    return axios
      .post(`${API.baseUrlV2}publications`, request)
      .then(mapResponse)
      .catch(printError);
  },

  publishCourseBatch(request) {
    return axios
      .post(`${API.baseUrlV2}publications/bulk`, request)
      .then(mapResponse)
      .catch(printError);
  },

  patchPublication(id, patch) {
    return axios
      .patch(`${API.baseUrlV2}publications/${id}`, patch)
      .then(mapResponse)
      .catch(printError);
  },

  deletePublication(id) {
    return axios
      .delete(`${API.baseUrlV2}publications/${id}`)
      .then(mapResponse)
      .catch(printError);
  },

  POST_CONTENT(payload) {
    const { id, ...result } = payload;

    if (id) {
      return axios
        .post(`${API.coursesURL}${payload.courseId}/content/${id}`, result)
        .then(mapResponse)
        .catch(printError);
    }

    return axios
      .post(`${API.coursesURL}${payload.courseId}/content/`, result)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to upload SCORM
   * @param {object} payload - formdata with files and course aid
   * @returns {Metadata} course metadata
   */
  UPLOAD_SCORM(payload) {
    return axios
      .post(`${API.coursesURL}content`, payload)
      .then(mapResponse)
      .catch(printError);
  },

  UPLOAD_STATIC_COURSE(payload) {
    return axios
      .post(`${API.baseUrl}staticCourses/`, payload)
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to update courses content
   * @param {object} payload - payload object
   * @param {number} payload.courseId - course id
   * @param {number} payload.contentId - content id
   * @param {(object)} payload.content - file (form data)
   * @param {string} payload.description - course description
   * @param {string} payload.notes - course notes
   * @param {array} payload.tags - array of strings
   * @param {array} payload.documents - array of documents
   * @param {object} payload.metadata - additional information
   * @returns {Course} updated course object
   */
  PATCH_SCORM(payload) {
    const { id, ...result } = payload;

    if (id) {
      return axios
        .patch(`${API.coursesURL}${payload.courseId}/content/${id}`, result)
        .then(mapResponse)
        .catch(printError);
    }
    return axios
      .patch(`${API.coursesURL}${payload.courseId}/content/`, result)
      .then(mapResponse)
      .catch(printError);
  },

  UPLOAD_QUIZ_AUTHORIZATIONS(payload) {
    if (!payload || !payload.get("courseId")) {
      return Promise.resolve({ error: "no quiz id in payload" });
    }

    const courseId = payload.get("courseId");

    return axios
      .post(`${API.quizzesURL}course/${courseId}/authorizations`, payload, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(mapResponse)
      .catch(printError);
  },
  /**
   * API call to upload Quiz
   * @param {object} payload - formdata with files and course aid
   * @returns {Metadata} course metadata
   */
  UPLOAD_QUIZ(payload) {
    if (!payload || !payload.get("courseId")) {
      return Promise.resolve({ error: "no course id in payload" });
    }
    const courseId = payload.get("courseId");
    return axios
      .post(`${API.quizzesURL}course/${courseId}`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(mapResponse)
      .catch(printError);
  },

  /**
   * API call to delete course by id
   * @param {number} id - course id
   * @returns {Promise<Response>} Fetch response, status 204 if success
   */
  DELETE_COURSE(id) {
    return axios
      .delete(API.coursesURL + id)
      .then(mapResponse)
      .catch(printError);
  },

  uploadAdditionalDocuments(courseId, body) {
    return axios
      .post(`${API.coursesURL}${courseId}/documents`, body)
      .then(mapResponse)
      .catch(printError);
  },

  deleteAdditionalDocuments(courseId, documentId) {
    return axios
      .delete(`${API.coursesURL}${courseId}/documents/${documentId}`)
      .then(mapResponse)
      .catch(printError);
  },

  uploadCertificate(formData) {
    return axios
      .post(`${API.generalUsersServices}courses/certificates`, formData)
      .then(mapResponse)
      .catch(printError);
  },

  deleteCertificate(id) {
    return axios
      .delete(`${API.generalUsersServices}courses/certificates/${id}`)
      .then(mapResponse)
      .catch(printError);
  },

  getUploadedCertificates({ userId, page, pageSize }) {
    return axios
      .get(
        `${API.generalUsersServices}courses/certificates/${userId}?page=${page}&pageSize=${pageSize}`,
      )
      .then(mapResponse)
      .catch(printError);
  },

  getCourseLocks(urlParams) {
    const parameters = new URLSearchParams(urlParams);
    return axios
      .get(`${API.baseUrlV2}course-locks?${parameters.toString()}`)
      .then(mapResponse)
      .catch(printError);
  },

  putCourseLock(payload) {
    return axios
      .put(`${API.baseUrlV2}course-locks`, payload)
      .then(mapResponse)
      .catch(printError);
  },
};
