import services from "@/services/myProgram";
import coursesService from "@/services/courses";
import API from "@/services/config";
import { saveAs } from "file-saver";
import { mapResponse, printError } from "../utils/mapResponse";
import { Courses } from "@inctec/elearning-ui-client";

export default {
  state: {
    list: [],
    completedList: [],
    assessmentList: [],
    certificate: "",
    isCertificateLoading: false,
  },
  mutations: {
    saveCertificate(state, payload) {
      if (payload) {
        state.certificate = payload;
      }
    },
    initProgram(state, payload) {
      if (payload && payload.length) {
        state.list = payload;
        state.list.forEach((itm) => {
          const item = itm;
          const { id } = item;
          item.image = `${API.coursesURL}${id}/pictures/image`;
          item.playImage = `${API.coursesURL}${id}/pictures/playImage`;
          item.cardImage = `${API.coursesURL}${id}/pictures/cardImage`;
        });
      } else {
        state.list = [];
      }
    },
    initCompletedList(state, payload) {
      // state.completedList = [];
      if (payload.length) {
        state.completedList = payload;
        state.completedList.forEach((itm) => {
          const item = itm;
          const { id } = item;
          item.image = `${API.coursesURL}${id}/pictures/image`;
          item.playImage = `${API.coursesURL}${id}/pictures/playImage`;
          item.cardImage = `${API.coursesURL}${id}/pictures/cardImage`;
        });
      }
    },
    initAssessmentList(state, payload) {
      // state.completedList = [];
      if (payload.length) {
        state.assessmentList = payload;
        state.assessmentList.forEach((itm) => {
          const item = itm;
          const { id } = item;
          item.image = `${API.coursesURL}${id}/pictures/image`;
          item.playImage = `${API.coursesURL}${id}/pictures/playImage`;
          item.cardImage = `${API.coursesURL}${id}/pictures/cardImage`;
        });
      }
    },
    addProgram(state, payload) {
      if (payload) {
        state.list.push(payload);
      }
    },
    markCourseAsCompleted(state, payload) {
      if (!payload) {
        return;
      }
      const { id } = payload;
      const foundedItem = state.list.find((item) => item.id === +id);
      if (foundedItem) {
        foundedItem.isCompleted = true;
      }
    },
    removeProgram(state, payload) {
      const list = state.list;
      const index = list.findIndex((item) => item.id === payload);
      if (index >= 0) {
        list.splice(index, 1);
      }
    },
    setCertificateLoading(state) {
      state.isCertificateLoading = true;
    },
    unsetCertificateLoading(state) {
      state.isCertificateLoading = false;
    },
    removeProgramCourseById(state, id) {
      if (id || id === 0) {
        state.list = state.list.filter((item) => item.id !== +id);
      }
    },
    flushMyProgram(state) {
      state.list = [];
    },
    flushCompletedList(state) {
      state.completedList = [];
    },
  },
  actions: {
    initProgram({ commit }) {
      Courses.searchUserCourses({
        body: {
          isAssigned: true,

          // not used at the moment
          page: 1,
          pageSize: 1,
        },
      }).then((data) => {
        if (data.data !== undefined) {
          const isLoaded = data.data.result.length;
          commit("initProgram", data.data.result);
          return isLoaded;
        }
      });
    },
    initCompletedList({ commit }) {
      return Courses.searchUserCourses({
        body: {
          /* not used at the moment */
          pageSize: 1,
          keys: {},
          isCompleted: true,
          withUserCertificates: true,
        },
      }).then(
        (response) => {
          if (response.data !== undefined) {
            commit("initCompletedList", response.data.result);
            // what for?
            return true;
          } else {
            commit("errorState", response.error);
            return Promise.reject(response.error);
          }
        },
        (error) => {
          commit("errorState", error);
        },
      );
    },
    initAssessmentList({ commit }) {
      return services.GET_ASSESSMENTS().then((data) => {
        const isLoaded = data && data.length;
        commit("initAssessmentList", data);
        return isLoaded;
      });
    },
    async addProgram({ commit, dispatch }, id) {
      return coursesService
        .ASSIGN_COURSE({
          course: id,
        })
        .then((response) => {
          dispatch("addCourseToProgram", id);
          commit("addNewNotification", {
            title: "success",
            text: "addedToProgram",
            notificationType: "success",
          });
        });
    },
    removeProgram({ commit, rootGetters }, payload) {
      const userData = rootGetters.getUserData;
      coursesService
        .UNASSIGN_COURSE({
          course: payload,
          users: [userData.id],
        })
        .then((data) => {
          commit("removeProgram", payload);
          commit("addNewNotification", {
            title: "success",
            text: "removedFromProgram",
            notificationType: "success",
          });
        });
    },
  },
  getters: {
    getLoadingCertificateStatus: (state) => state.isCertificateLoading,
    getMyCourses: (state) => state.list,
    getMyCompletedCourses: (state) => state.completedList,
    getAssessments: (state) => state.assessmentList,
    getProgramCourseById: (state) => (id) =>
      state.list.find((item) => item.id === id),
    getCompletedCourseById: (state) => (id) =>
      state.completedList.find((item) => item.id === id),
    isCourseInMyProgram: (state) => (id) => {
      // state.list.some(item => item.id === id)
      // mock at the moment
      const flag = state.list.some((item) => item.id === id);
      if (flag) {
        return true;
      }
      return false;
    },
  },
};
