import {
  Accounts,
  Reports,
  Events,
  CourseCompletions,
  CourseLocks,
  SignupKeys,
  Documents,
  Monitoring,
} from "@inctec/shogun";
import { CoursesClient, GeneralClient } from "@inctec/elearning-ui-client";
import { BaseHttpRequest, request as __request } from "@inctec/shogun";
import axios from "axios";
import { printError } from "../utils/mapResponse";

export class AxiosHttpRequestWithRetry extends BaseHttpRequest {
  constructor(config) {
    super(config);
  }

  request(options) {
    return __request(this.config, options, axios).catch(printError);
  }
}

/**
 * generated from openApi
 */

export const monitoring = new Monitoring(
  {
    BASE: "/services-monitoring",
  },
  AxiosHttpRequestWithRetry,
);

export const accounts = new Accounts(
  {
    BASE: "/services/api/v2",
  },
  AxiosHttpRequestWithRetry,
);

export const documentsApi = new Documents(
  {
    BASE: "/services-documents",
  },
  AxiosHttpRequestWithRetry,
);

export const signupKeys = new SignupKeys(
  {
    BASE: "/services/api/v2",
  },
  AxiosHttpRequestWithRetry,
);

export const reports = new Reports(
  {
    BASE: "/services",
  },
  AxiosHttpRequestWithRetry,
);

export const eventsApi = new Events({
  BASE: "/services",
  AxiosHttpRequestWithRetry,
});

export const recognitionsApi = new CourseCompletions({
  BASE: "/services",
  AxiosHttpRequestWithRetry,
});

export const locksApi = new CourseLocks({
  BASE: "/services",
  AxiosHttpRequestWithRetry,
});

GeneralClient.setConfig({
  baseURL: "/services",
});

CoursesClient.setConfig({
  baseURL: "/services-general",
});
